import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Input,
  InputSelect,
  LoadingOverlay,
  PageLayout,
  PageTitle,
  useMutation,
  useQuery,
} from '../../../../../mds_design_system';
import batchAPI from '../../../apis/batches';
import document_status from '../../../data/document_status.json';
import useForm from '../../../hooks/useForm';
import { Batch } from '../../../types';
import moduleConfig from '../config';
import pageConfig from './config';

const BatchUpdatePage = () => {
  const params = useParams();
  const { setData, getData, initData } = useForm();
  const navigate = useNavigate();
  const {
    call,
    loading: mutationLoading,
    error,
  } = useMutation(batchAPI.patch, {
    onSuccess: () => navigate(moduleConfig.baseURL),
  });

  const { loading: queryLoading } = useQuery(batchAPI.get, {
    params: { id: params.id },
    onSuccess: (response: Batch) => {
      initData({
        id: response.id,
        name: response.name,
        start_date: response.start_date,
        end_date: response.end_date,
        status: response.status,
      });
    },
  });
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <form className="flex flex-col relative bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl w-full md:w-1/2">
        {(mutationLoading || queryLoading) && <LoadingOverlay />}
        <div className="p-8 space-y-2">
          <Input
            label="Name"
            value={getData('name')}
            onChange={event => setData('name', event.target.value)}
            error={_.get(error, 'response.data.name')}
          />
          <Input
            label="Start Date"
            type="date"
            value={getData('start_date')}
            onChange={event => setData('start_date', event.target.value)}
            error={_.get(error, 'response.data.start_date')}
          />
          <Input
            label="End Date"
            type="date"
            value={getData('end_date')}
            onChange={event => setData('end_date', event.target.value)}
            error={_.get(error, 'response.data.end_date')}
          />
          <InputSelect
            label="Status"
            options={document_status}
            value={getData('status')}
            onChange={value => setData('status', value)}
            error={_.get(error, 'response.data.status')}
          />
        </div>
        {error && <Alert icon="error" title={error.message} />}
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button
            onClick={() => call({ id: getData('id'), data: getData() })}
            disabled={mutationLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </PageLayout>
  );
};

export default BatchUpdatePage;
