import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BatchCreatePage from './pages/batches/create';
import BatchListPage from './pages/batches/list';
import BatchUpdatePage from './pages/batches/update';
import DocumentAnnotatePage from './pages/documents/annotate';
import DocumentCreatePage from './pages/documents/create';
import DocumentListPage from './pages/documents/list';
import DocumentUpdatePage from './pages/documents/update';

const MDSAnnotationToolRoutes = () => {
  return (
    <Routes>
      <Route path="documents" element={<DocumentListPage />} />
      <Route path="documents/create" element={<DocumentCreatePage />} />
      <Route path="documents/:id/annotate" element={<DocumentAnnotatePage />} />
      <Route path="documents/:id/update" element={<DocumentUpdatePage />} />
      <Route path="batches" element={<BatchListPage />} />
      <Route path="batches/create" element={<BatchCreatePage />} />
      <Route path="batches/:id/update" element={<BatchUpdatePage />} />
    </Routes>
  );
};

export default MDSAnnotationToolRoutes;
