import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Table, TD, TH } from '../../../../../../mds_design_system';
import DataContext from '../../../../contexts/DataContext';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import schema from '../../../../data/schema.json';
import MetricStatusIcon from './metric_status_icon';

type TableProps = {
  searchText?: string;
};

const IndicatorTable = (props: TableProps): JSX.Element => {
  const [selectedIndicator, setSelectedIndicator] = useRecoilState(
    InteractiveContext.selectedIndicatorState,
  );
  const [data] = useRecoilState(DataContext.dataState);
  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <TH />
          <TH>FW</TH>
          <TH>KEY_ID</TH>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {Object.keys(schema.properties)
          .filter(
            (key: any) =>
              props.searchText == null ||
              props.searchText.length === 0 ||
              key.toUpperCase().includes(props.searchText.toUpperCase()) ||
              _.get(schema, `properties.${key}.description`, '')
                .toUpperCase()
                .includes(props.searchText.toUpperCase()),
          )
          .map(key => {
            const item = _.get(data, key);
            return (
              <tr
                key={key}
                className={classNames({
                  'cursor-pointer': true,
                  'bg-gray-100': key === selectedIndicator,
                })}
                data-tooltip-id="metric-tooltip"
                data-tooltip-content={_.get(
                  schema,
                  `properties.${key}.description`,
                  '',
                )}
                onClick={() => setSelectedIndicator(key)}
              >
                <TD>
                  <IndicatorIconWithItem item={item} />
                </TD>
                <TD>APS</TD>
                <TD>
                  <span className="text-nexus-600 underline">{key}</span>
                </TD>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

type IndicatorIconWithItemProps = {
  item?: any;
};

const IndicatorIconWithItem = (props: IndicatorIconWithItemProps) => {
  if (props.item) {
    if (props.item.no_disclosure) {
      return <MetricStatusIcon type="no-disclosure" />;
    }
    if (props.item.items && props.item.items.length > 0) {
      const completed = props.item.items.every((item: any) => {
        if (!item) return false;
        if (!item['DISCLOSURE']) return false;
        if (!item['UNIT']) return false;
        if (!item['POSITION']) return false;
        return true;
      });
      if (completed) {
        return <MetricStatusIcon type="completed" />;
      } else {
        return <MetricStatusIcon type="in-progress" />;
      }
    }
  }
  return <MetricStatusIcon type="to-do" />;
};

export default IndicatorTable;
