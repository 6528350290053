import * as React from 'react';
import ReactSelect from 'react-select';
import DataContext from '../../../../contexts/DataContext';

type InputProps = {
  indicator: string;
  index: number;
};

const options = [
  { value: 'table', label: 'Table' },
  { value: 'paragraph', label: 'Paragraph' },
  { value: 'chart', label: 'Chart' },
  { value: 'graphic', label: 'Graphic' },
];

const Select = (props: InputProps) => {
  const { getData, setData } = DataContext.useData();
  const dataPath = DataContext.path(
    props.indicator,
    'items',
    props.index,
    'POSITION',
  );
  const value = getData(dataPath, '');
  return (
    <ReactSelect
      onChange={value => {
        if (value) {
          setData(value.value, dataPath);
        }
      }}
      value={options.find(item => item.value === value)}
      options={options}
    />
  );
};

export default Select;
