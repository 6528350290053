import { atom } from 'recoil';

const userTokenState = atom({
  key: 'UserTokenState',
  default: null,
  effects: [
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem('UserTokenState');
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem('UserTokenState')
          : localStorage.setItem('UserTokenState', JSON.stringify(newValue));
      });
    },
  ],
});

const AuthContext = {
  userTokenState,
};

export default AuthContext;
