import { ToolbarProps } from '@react-pdf-viewer/default-layout';
import classNames from 'classnames';
import { MouseEventHandler, ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { Icon } from '../../../../../../../mds_design_system';
import InteractiveContext from '../../../../../contexts/InteractiveContext';

const HighlightToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => {
  const [selectedSelectionMode, setSelectedSelectionMode] = useRecoilState(
    InteractiveContext.selectedSelectionMode,
  );
  return (
    <>
      <span className="isolate inline-flex rounded-md shadow-sm ml-2">
        <ToolbarButton
          icon="text_select_move_forward_character"
          first
          onClick={() => setSelectedSelectionMode('Text')}
          selected={selectedSelectionMode === 'Text'}
        />
        <ToolbarButton
          icon="point_scan"
          last
          onClick={() => setSelectedSelectionMode('Area')}
          selected={selectedSelectionMode === 'Area'}
        />
      </span>
      <Toolbar />
    </>
  );
};

type ToolbarButtonProps = {
  first?: boolean;
  last?: boolean;
  selected?: boolean;
  icon: string;
  onClick: MouseEventHandler | undefined;
};
const ToolbarButton = (props: ToolbarButtonProps) => (
  <button
    onClick={props.onClick}
    type="button"
    className={classNames({
      'relative inline-flex items-center px-2 py-1 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10':
        true,
      'rounded-l-md': props.first,
      'rounded-r-md': props.last,
      'bg-nexus-200': props.selected,
      'bg-white': !props.selected,
    })}
  >
    <Icon icon={props.icon} className="text-base text-gray-400" />
  </button>
);

export default HighlightToolbar;
