import axios from 'axios';
import configs from '../configs/config';
import { Batch } from '../types';

const apiConfig = {
  path: configs.local.baseURL + configs.local.apis.batches,
};

const all = (params: { page: number; searchText: string }) => {
  return axios.get(apiConfig.path, {
    params: {
      page: params.page + 1,
      search: params.searchText.length ? params.searchText : null,
    },
  });
};

const get = (params: { id: string }) => {
  return axios.get<Batch>(apiConfig.path + '/' + params.id);
};

const create = (params: { data: any }) =>
  axios.post(apiConfig.path, params.data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const patch = (params: { id: string; data: any }) => {
  return axios.patch(apiConfig.path + '/' + params.id, params.data);
};

const batchAPI = {
  all,
  create,
  get,
  patch,
};

export default batchAPI;
