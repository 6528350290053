import * as React from 'react';
import { Icon } from '../libraries/mds_design_system';

const sideMenuConfig = [
  {
    label: 'Home',
    icon: <Icon icon="home" />,
    link: '/app',
  },
  {
    label: '[WIP] Annotation Tool',
    icon: <Icon icon="fact_check" />,
    link: '/app/annotation_tool/documents',
  },
  {
    label: 'Passport',
    icon: <Icon icon="assignment_ind" />,
    link: '/app/passport_extraction/upload',
  },
  {
    label: 'Driving License',
    icon: <Icon icon="badge" />,
    link: '/app/driving_license_extraction/upload',
  },
  {
    label: 'Utility Bill',
    icon: <Icon icon="receipt_long" />,
    link: '/app/utility_bill_extraction/upload',
  },
  {
    label: 'Bank Statement',
    icon: <Icon icon="account_balance" />,
    link: '/app/bank_statement_extraction/upload',
  },
  {
    label: 'Financial Report',
    icon: <Icon icon="list_alt" />,
    link: '/app/financial_report_extraction/upload',
  },
  {
    label: 'ESG Data Scraper',
    icon: <Icon icon="smart_toy" />,
    link: '/app/esg_data_scraper/upload',
  },
  {
    label: 'User Management',
    icon: <Icon icon="person" />,
    link: '/admin/users',
  },
];

export default sideMenuConfig;
