import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Badge,
  Box,
  Icon,
  Input,
  LoadingOverlay,
  Pagination,
  Table,
  TD,
  TH,
  useQuery,
} from '../../../../../mds_design_system';
import { getDocuments } from '../../../apis/documents';

const DocumentList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [searchText, setSearchText] = useState('');
  const { response, error, loading } = useQuery(getDocuments, {
    params: { page, searchText },
    reloadOn: [page, searchText],
  });
  return (
    <Box className="w-full space-y-4 relative" padding={false}>
      {loading && <LoadingOverlay />}
      {error && <Alert icon="error" title={error.message} />}
      <div className="my-4 mx-6">
        <div className="w-3/12">
          <Input
            leftIcon="search"
            placeholder="Search by document name"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
        </div>
      </div>
      <Table>
        <thead className="bg-gray-50">
          <tr>
            <TH>DOC ID</TH>
            <TH>DOCUMENT NAME</TH>
            <TH>COMPANY ID</TH>
            <TH>TYPE</TH>
            <TH>YEAR</TH>
            <TH>LANGUAGE</TH>
            <TH>BATCH</TH>
            <TH>STATUS</TH>
            <TH></TH>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {response &&
            response.results.map((item: any) => (
              <tr
                key={item.id}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => navigate(`${item.id}/annotate`)}
              >
                <TD>{item.id}</TD>
                <TD>{item.name}</TD>
                <TD>{item.company_id}</TD>
                <TD>{item.type}</TD>
                <TD>{item.year}</TD>
                <TD>{item.language}</TD>
                <TD>{item.batch ? item.batch.name : 'N/A'}</TD>
                <TD>
                  <Badge type="primary">{item.status}</Badge>
                </TD>
                <TD>
                  <button
                    onClick={event => {
                      event.stopPropagation();
                      navigate(
                        `/app/annotation_tool/documents/${item.id}/update`,
                      );
                    }}
                  >
                    <Icon icon="edit" />
                  </button>
                  <button>
                    <Icon icon="delete" />
                  </button>
                </TD>
              </tr>
            ))}
        </tbody>
      </Table>
      {response && (
        <Pagination
          page={page}
          size={size}
          total={response.count}
          resultCount={response.results.length}
          onChange={newPage => setPage(newPage)}
        />
      )}
    </Box>
  );
};

export default DocumentList;
