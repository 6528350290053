import axios from 'axios';
import configs from '../configs/config';

const getDocuments = (params: { page: number; searchText: string }) =>
  axios.get(configs.local.baseURL + configs.local.apis.documents, {
    params: {
      page: params.page + 1,
      search: params.searchText.length ? params.searchText : null,
    },
  });

const getDocument = (params: { id: string }) => {
  return axios.get(
    configs.local.baseURL + configs.local.apis.documents + '/' + params.id,
  );
};

const updateDocument = (params: { id: string; data: any }) => {
  return axios.patch(
    configs.local.baseURL + configs.local.apis.documents + '/' + params.id,
    params.data,
  );
};

const createDocument = (params: { data: any }) => {
  return axios.post(
    configs.local.baseURL + configs.local.apis.documents,
    params.data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export { getDocuments, getDocument, updateDocument, createDocument };
