import { ChangeEventHandler } from 'react';
import { Icon } from '../../atoms/Icon';

type InputProps = {
  label?: string;
  value: string;
  type?: 'text' | 'number' | 'password' | 'date';
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  error?: Array<string>;
  leftIcon?: string;
  rightIcon?: string;
};

const Input = (props: InputProps) => {
  return (
    <div className="w-full">
      {props.label && (
        <label
          htmlFor="website"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
      )}
      <div className="mt-2">
        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 px-2 items-center">
          {props.leftIcon && (
            <Icon className="text-gray-300" icon={props.leftIcon} />
          )}
          <input
            type={props.type}
            name="website"
            id="website"
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
          />
          {props.rightIcon && (
            <Icon className="text-gray-300" icon={props.rightIcon} />
          )}
        </div>
        {props.error &&
          props.error.map(error => (
            <p className="mt-3 text-sm leading-6 text-red-600">{error}</p>
          ))}
      </div>
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
};

export { Input, type InputProps };
