const pageConfig = {
  breadcrumb: [
    {
      label: 'MDS Annotation Tool',
      link: '/app/annotation_tool/documents',
    },
    {
      label: 'Documents',
      link: '/app/annotation_tool/documents',
    },
  ],
  pageTitle: 'MDS Annotation Tool - Annotate Document',
  header: 'Annotate Document',
};

export default pageConfig;
