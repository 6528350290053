import { useNavigate } from 'react-router-dom';
import {
  Button,
  PageLayout,
  PageTitle,
} from '../../../../../mds_design_system';
import pageConfig from './config';
import BatchTable from './table';

const BatchListPage = () => {
  const navigate = useNavigate();
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <div className="w-full space-y-2">
        <div className="flex justify-between">
          <div />
          <Button
            onClick={() => navigate('/app/annotation_tool/batches/create')}
          >
            New Batch
          </Button>
        </div>
        <BatchTable />
      </div>
    </PageLayout>
  );
};

export default BatchListPage;
