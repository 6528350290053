import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useContext, useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';

import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../../mds_design_system';
import ConfigContext from '../contexts/config_context';

const ResultPage = (): JSX.Element => {
  const context = useContext(ConfigContext);
  const breadcrumbItems = [
    {
      label: context.modelName,
      link: context.basePath + '/upload',
    },
    {
      label: 'Result',
    },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    if (!location.state.file) {
      navigate(context.basePath + '/upload');
    }
    const objectUrl = URL.createObjectURL(location.state.file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [location.state.file, navigate, context.basePath]);

  return (
    <PageLayout
      breadcrumbItems={breadcrumbItems}
      headerText={context.modelName}
      fullHeight
    >
      <div className="w-6/12">
        <div className="h-full">
          {location.state.file.name.split('.').pop() === 'pdf' ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
              <div
                style={{
                  height: `100%`,
                  width: '100%',
                }}
              >
                <Viewer
                  fileUrl={preview}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          ) : (
            <img
              className="h-full w-full object-contain bg-white"
              src={preview}
              alt="Source Document"
            />
          )}
        </div>
      </div>
      <div className="w-6/12">
        <div
          className="h-full overflow-auto"
          style={{ backgroundColor: 'rgb(50, 41, 49)' }}
        >
          <JSONTree
            data={location.state.result}
            shouldExpandNodeInitially={() => true}
          />
          ;
        </div>
      </div>
    </PageLayout>
  );
};

export default ResultPage;
