import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Alert,
  Box,
  Icon,
  Input,
  LoadingOverlay,
  Pagination,
  Table,
  TD,
  TH,
  useQuery,
} from '../../../../../mds_design_system';
import userAPI from '../../../apis/users';
import AuthContext from '../../../contexts/AuthContext';
import { type User } from '../../../types';
import moduleConfig from '../config';

const UserTable = () => {
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [searchText, setSearchText] = useState('');
  const token = useRecoilValue(AuthContext.userTokenState);
  const { response, error, loading } = useQuery(userAPI.all, {
    params: { page, searchText, token },
    reloadOn: [page, searchText],
  });
  return (
    <Box className="w-full space-y-4 relative" padding={false}>
      {loading && <LoadingOverlay />}
      {error && <Alert icon="error" title={error.message} />}
      <div className="my-4 mx-6">
        <div className="w-3/12">
          <Input
            leftIcon="search"
            placeholder="Search by name"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
        </div>
      </div>
      <Table>
        <thead className="bg-gray-50">
          <Header />
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {response && response.results.map((item: any) => <Row item={item} />)}
        </tbody>
      </Table>
      {response && (
        <Pagination
          page={page}
          size={size}
          total={response.count}
          resultCount={response.results.length}
          onChange={newPage => setPage(newPage)}
        />
      )}
    </Box>
  );
};

const Header = () => (
  <tr>
    <TH>ID</TH>
    <TH>USERNAME</TH>
    <TH>FIRST NAME</TH>
    <TH>LAST NAME</TH>
    <TH>EMAIL</TH>
    <TH></TH>
  </tr>
);

type RowProps = {
  item: User;
};

const Row = (props: RowProps) => {
  const item = props.item;
  const navigate = useNavigate();
  return (
    <tr key={item.id} className="hover:bg-gray-50">
      <TD>{item.id}</TD>
      <TD>{item.username}</TD>
      <TD>{item.first_name}</TD>
      <TD>{item.last_name}</TD>
      <TD>{item.email}</TD>
      <TD>
        <button
          onClick={() => {
            navigate(`${moduleConfig.baseURL}/${item.id}/update`);
          }}
        >
          <Icon icon="edit" />
        </button>
        <button>
          <Icon icon="delete" />
        </button>
      </TD>
    </tr>
  );
};

export default UserTable;
