import classNames from 'classnames';
import * as React from 'react';
import { Icon, TD } from '../../../../../../mds_design_system';
import Input from './input';
import Select from './select';

type RowProps = {
  indicator: string;
  index: number;
  new?: boolean;
  highlight?: boolean;
  onDelete?(): void;
  id: string;
};

const Row = (props: RowProps) => (
  <tr className={classNames({ 'bg-nexus-300': props.highlight })} id={props.id}>
    <TD>
      <Input indicator={props.indicator} index={props.index} type="LABEL_1" />
    </TD>
    <TD>
      <Input indicator={props.indicator} index={props.index} type="LABEL_2" />
    </TD>
    <TD>
      <Input indicator={props.indicator} index={props.index} type="LABEL_3" />
    </TD>
    <TD>
      <Input
        indicator={props.indicator}
        index={props.index}
        type="DISCLOSURE"
      />
    </TD>
    <TD>
      <Input indicator={props.indicator} index={props.index} type="UNIT" />
    </TD>
    <TD>
      <Select indicator={props.indicator} index={props.index} />
    </TD>
    <TD>
      {!props.new && (
        <button onClick={() => props.onDelete && props.onDelete()}>
          <Icon icon="delete" />
        </button>
      )}
    </TD>
  </tr>
);

export default Row;
