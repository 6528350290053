import React from 'react';

type ConfigContextType = {
  modelName: string;
  basePath: string;
  fileTypes: Array<string>;
  maxSize: number;
  extractionApi: string;
};

const ConfigContext = React.createContext<ConfigContextType>({
  modelName: '',
  basePath: '',
  fileTypes: [],
  maxSize: 0,
  extractionApi: '',
});

export default ConfigContext;
