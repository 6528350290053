import { useEffect } from 'react';

type PageTitleProps = {
  title: string;
};

const PageTitle = (props: PageTitleProps) => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return null;
};

export { PageTitle, type PageTitleProps };
