import { RenderPage, RenderPageProps } from '@react-pdf-viewer/core';
import classNames from 'classnames';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import HighlightContextMenu from './highlight_context_menu';

type Position = {
  top: number;
  left: number;
};

const CustomRenderPage: RenderPage = (props: RenderPageProps) => {
  const [startPoint, setStartPoint] = useState<Position | undefined>();
  const [currentPoint, setCurrentPoint] = useState<Position | undefined>();
  const [endPoint, setEndPoint] = useState<Position | undefined>();
  const [showContext, setShowContext] = useState(false);
  const [longPress, setLongPress] = useState(false);

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [selectedSelectionMode] = useRecoilState(
    InteractiveContext.selectedSelectionMode,
  );
  const pageRect = ref ? ref.getBoundingClientRect() : null;
  return (
    <div
      className="h-full w-full"
      ref={_ref => setRef(_ref)}
      onMouseDown={event => {
        if (!pageRect) return;
        if (selectedSelectionMode !== 'Area') return;
        const top = ((event.clientY - pageRect.top) * 100) / pageRect.height;
        const left = ((event.clientX - pageRect.left) * 100) / pageRect.width;

        setStartPoint({ top, left });
        setLongPress(true);
        setEndPoint(undefined);
        setShowContext(false);
      }}
      onMouseMove={event => {
        if (!pageRect) return;
        if (selectedSelectionMode !== 'Area') return;
        const top = ((event.clientY - pageRect.top) * 100) / pageRect.height;
        const left = ((event.clientX - pageRect.left) * 100) / pageRect.width;

        if (longPress && pageRect && selectedSelectionMode === 'Area') {
          setCurrentPoint({ top, left });
        }
      }}
      onMouseUp={event => {
        if (!pageRect) return;
        if (selectedSelectionMode !== 'Area') return;
        const top = ((event.clientY - pageRect.top) * 100) / pageRect.height;
        const left = ((event.clientX - pageRect.left) * 100) / pageRect.width;
        if (longPress && top !== startPoint?.top && left !== startPoint?.left) {
          setShowContext(true);
          setEndPoint({ top, left });
        }
        setLongPress(false);
      }}
    >
      {props.canvasLayer.children}
      {props.annotationLayer.children}
      <div
        className={classNames({
          'select-none': selectedSelectionMode === 'Area',
        })}
      >
        {props.textLayer.children}
      </div>
      {startPoint && currentPoint && longPress ? (
        <div
          className="absolute bg-nexus-700 opacity-50"
          style={{
            top: startPoint.top + '%',
            left: startPoint.left + '%',
            height: currentPoint.top - startPoint.top + '%',
            width: currentPoint.left - startPoint.left + '%',
          }}
        />
      ) : null}
      {startPoint && endPoint ? (
        <div
          className="absolute bg-nexus-700 opacity-50"
          style={{
            top: startPoint.top + '%',
            left: startPoint.left + '%',
            height: endPoint.top - startPoint.top + '%',
            width: endPoint.left - startPoint.left + '%',
          }}
        />
      ) : null}
      {showContext && startPoint && endPoint ? (
        <HighlightContextMenu
          selectionRegion={{
            top: startPoint.top,
            left: startPoint.left,
            height: endPoint.top - startPoint.top,
            width: endPoint.left - startPoint.left,
            pageIndex: props.pageIndex,
          }}
          highlightAreas={[
            {
              top: startPoint.top,
              left: startPoint.left,
              height: endPoint.top - startPoint.top,
              width: endPoint.left - startPoint.left,
              pageIndex: props.pageIndex,
            },
          ]}
          selectedText={''}
          cancel={() => {
            setShowContext(false);
            setStartPoint(undefined);
            setEndPoint(undefined);
            setCurrentPoint(undefined);
          }}
        />
      ) : null}
    </div>
  );
};

export default CustomRenderPage;
