import { useEffect } from 'react';
import { useQuery } from '../../../utils/hooks';
import { InputSelect } from '../InputSelect';

type InputSelectAPIProps = {
  label?: string;
  value: any;
  onChange: (value: string | null) => void;
  error?: Array<string>;
  query: (params: any) => Promise<any>;
  params?: any;
  onLoad: (response: any) => [OptionProps];
  isClearable?: boolean;
  isMulti?: boolean;
  callRef?: any;
};

type OptionProps = {
  label: string;
  value: any;
};

const InputSelectAPI = (props: InputSelectAPIProps) => {
  const { response, error, loading, call } = useQuery(props.query, {
    params: props.params,
    reloadOn: [props.params?.time],
  });

  useEffect(() => props.callRef && props.callRef(call), []);

  if (loading) return <div>Loading</div>;
  if (error) return <div>{error.message}</div>;
  if (!response) return <div>Loading</div>;

  const options = props.onLoad(response);

  return <InputSelect {...props} options={options} />;
};

export { InputSelectAPI, type InputSelectAPIProps };
