type ResultCountProps = {
  page: number;
  size: number;
  resultCount: number;
  total: number;
};

const ResultCount = (props: ResultCountProps) => {
  return (
    <div>
      <p className="text-sm text-gray-700">
        Showing <span className="font-bold">{props.page * props.size + 1}</span>{' '}
        to{' '}
        <span className="font-bold">
          {props.page * props.size + props.resultCount}
        </span>{' '}
        of <span className="font-bold">{props.total}</span> results{' '}
      </p>
    </div>
  );
};

export default ResultCount;
