import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { Icon } from '../../../../../../mds_design_system';
import DataContext from '../../../../contexts/DataContext';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import schema from '../../../../data/schema.json';
import InputTable from './table';

type InputBoxProps = {
  language: string;
};

const InputBox = (props: InputBoxProps) => {
  const [selectedIndicator, setSelectedIndicator] = useRecoilState(
    InteractiveContext.selectedIndicatorState,
  );
  const { setData, getData } = DataContext.useData();
  if (!selectedIndicator) return null;
  const metricData = _.get(schema.properties, selectedIndicator);
  const keywords = _.get(metricData, `KEYWORDS.${props.language}`, []);

  return (
    <div className="bg-white h-full p-4 overflow-y-auto overflow-x-hidden">
      <div className="flex justify-between">
        <div className="text-gray-800 font-normal text-xl">
          {selectedIndicator}, {metricData.description}
        </div>
        <div
          onClick={() => setSelectedIndicator(null)}
          className="cursor-pointer"
        >
          <Icon icon="close" />
        </div>
      </div>
      <div className="text-nexus-600 font-medium text-base mt-4">
        Search terms
      </div>
      <div className="text-gray-600 font-normal text-sm">
        {keywords.map((keyword: string, index: number) => (
          <>
            <span>{keyword}</span>
            {index !== keywords.length - 1 && ', '}
          </>
        ))}
      </div>
      <div className="relative flex gap-x-3 mt-4">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onChange={event =>
              setData(
                event.target.checked,
                DataContext.path(selectedIndicator, 'no_disclosure'),
              )
            }
            checked={getData(
              DataContext.path(selectedIndicator, 'no_disclosure'),
            )}
          />
        </div>
        <div className="text-sm leading-6">
          <label htmlFor="comments" className="font-medium text-gray-900">
            No Disclosure
          </label>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <InputTable selectedIndicator={selectedIndicator} />
      </div>
    </div>
  );
};

export default InputBox;
