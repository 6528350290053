import { useNavigate } from 'react-router-dom';
import {
  Button,
  PageLayout,
  PageTitle,
} from '../../../../../mds_design_system';
import pageConfig from './config';
import DocumentList from './table';

const DocumentListPage = () => {
  const navigate = useNavigate();
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <div className="w-full space-y-2">
        <div className="flex justify-between">
          <div />
          <div className="space-x-2">
            <Button onClick={() => navigate('/app/annotation_tool/batches')}>
              Manage Batch
            </Button>
            <Button
              onClick={() => navigate('/app/annotation_tool/documents/create')}
            >
              Upload Document
            </Button>
          </div>
        </div>
        <DocumentList />
      </div>
    </PageLayout>
  );
};

export default DocumentListPage;
