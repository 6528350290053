import classNames from 'classnames';
import * as React from 'react';

type THProps = {
  children?: React.ReactNode;
  className?: any;
};

const TH = (props: THProps): JSX.Element => {
  return (
    <th
      scope="col"
      className={classNames(
        'px-2.5 py-3 text-left text-sm font-semibold text-gray-500 bg-gray-200',
        props.className,
      )}
    >
      {props.children}
    </th>
  );
};

export { TH, type THProps };
