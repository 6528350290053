import { HighlightArea } from '@react-pdf-viewer/highlight';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import DataContext from '../../../../contexts/DataContext';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import positions from '../../../../data/annotation_position.json';
import types from '../../../../data/annotation_type.json';

type HighlightContextMenuProps = {
  selectionRegion: HighlightArea;
  highlightAreas: Array<HighlightArea>;
  selectedText: string;
  cancel(): void;
};

const HighlightContextMenu = (props: HighlightContextMenuProps) => {
  console.log(props);
  const [selectedIndicator] = useRecoilState(
    InteractiveContext.selectedIndicatorState,
  );
  const [selectedIndex] = useRecoilState(
    InteractiveContext.selectedIndicatorIndexState,
  );
  const { setData } = DataContext.useData();

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: any) => {
      const label = types.find(type => type.hotKey === event.key);
      if (label) {
        handleAdd(label.label);
      } else {
        const position = positions.find(type => type.hotKey === event.key);
        if (position) {
          handlePosition(position.label);
        }
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  });
  if (!selectedIndicator) return null;
  const handleAdd = (type: string) => {
    setData(
      {
        text: props.selectedText,
        page: props.selectionRegion.pageIndex,
        bbox: props.highlightAreas,
      },
      DataContext.path(selectedIndicator, 'items', selectedIndex, type),
    );
    props.cancel();
  };
  const handlePosition = (position: string) => {
    setData(
      position,
      DataContext.path(selectedIndicator, 'items', selectedIndex, 'POSITION'),
    );
  };

  return (
    <div
      className="flex absolute"
      style={{
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        zIndex: 1,
      }}
    >
      <div className="flex left-0 z-10 mt-2 w-80 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 w-40" role="none">
          {types.map(type => (
            <span
              key={type.label}
              className="font-medium text-gray-900 pl-4 pr-2 py-2 text-sm cursor-pointer hover:bg-gray-100 flex justify-between"
              onMouseDown={event => {
                event.stopPropagation();
                handleAdd(type.label);
              }}
            >
              {type.label}
              <span className="inline-flex items-center rounded-md bg-nexus-100 px-2.5 py-0.5 text-sm font-medium text-nexus-600">
                {type.hotKey}
              </span>
            </span>
          ))}
        </div>
        <div className="py-1 w-40" role="none">
          {positions.map(type => (
            <span
              key={type.label}
              className="font-medium text-gray-900 pl-4 pr-2 py-2 text-sm cursor-pointer hover:bg-gray-100 flex justify-between"
              onMouseDown={event => {
                event.stopPropagation();
                handlePosition(type.label);
              }}
            >
              {type.label}
              <span className="inline-flex items-center rounded-md bg-nexus-100 px-2.5 py-0.5 text-sm font-medium text-nexus-600">
                {type.hotKey}
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HighlightContextMenu;
