import { Button } from '../../../../../../mds_design_system';

type InfoBoxProps = {
  type: string;
  year: number;
  name: string;
  file: string;
  onSave: () => any;
};

const InfoBox = (props: InfoBoxProps) => {
  return (
    <div>
      <div className="text-gray-800 font-medium text-lg">
        {filename(props.file)}
      </div>
      <div className="text-gray-600 font-normal text-xs">
        <span className="text-gray-900 font-medium">Doc Type:</span>{' '}
        {props.type} {props.year}
      </div>
      <div className="text-gray-600 font-normal text-xs">{props.name}</div>
      <Button onClick={props.onSave}>Save</Button>
    </div>
  );
};

const filename = (file: string) => {
  const regex = /amazonaws\.com\/(.*)\?/gm;
  let m;
  while ((m = regex.exec(file)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    return m[1];
  }
  return null;
};

export default InfoBox;
