import type { RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import DataContext from '../../../../contexts/DataContext';

type HighlightAreaProps = {
  height: number;
  left: number;
  pageIndex: number;
  top: number;
  width: number;
};

const HighlightAreas = (props: RenderHighlightsProps) => {
  const { data } = DataContext.useData();
  const areas = convertAreas(data);
  return (
    <div>
      {areas
        .filter(area => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area bg-nexus-400 opacity-50"
            style={props.getCssProperties(area, props.rotation)}
          />
        ))}
    </div>
  );
};

const convertAreas = (data: { [x: string]: any }) => {
  const areas = new Array<HighlightAreaProps>();
  const keys = Object.keys(data);
  for (const key of keys) {
    const items = data[key].items;
    if (!items) continue;
    for (const item of items) {
      if (!item) continue;
      const labels = Object.keys(item);
      for (const label of labels) {
        const dataPoint = item[label];
        if (!dataPoint.bbox) continue;
        for (const bbox of dataPoint.bbox) {
          areas.push(bbox);
        }
      }
    }
  }

  return areas;
};

export default HighlightAreas;
