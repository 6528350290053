import NextBackButton from './next_back_button';
import PageSelect from './page_select';
import ResultCount from './result_count';

type PaginationProps = {
  page: number;
  size: number;
  total: number;
  resultCount: number;
  onChange: (page: number) => any;
};

const Pagination = (props: PaginationProps) => {
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 w-full">
      <NextBackButton {...props} />
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <ResultCount {...props} />
        <PageSelect {...props} />
      </div>
    </div>
  );
};

export { Pagination, type PaginationProps };
