import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Alert,
  Button,
  Input,
  LoadingOverlay,
  PageLayout,
  PageTitle,
  useForm,
  useMutation,
  useQuery,
} from '../../../../../mds_design_system';
import userAPI from '../../../apis/users';
import AuthContext from '../../../contexts/AuthContext';
import moduleConfig from '../config';
import pageConfig from './config';

const UserUpdatePage = () => {
  const params = useParams();
  const token = useRecoilValue(AuthContext.userTokenState);
  const { setData, getData, initData } = useForm();
  const navigate = useNavigate();
  const {
    call,
    loading: mutationLoading,
    error,
  } = useMutation(userAPI.patch, {
    onSuccess: () => navigate(moduleConfig.baseURL),
  });

  const { loading: queryLoading } = useQuery(userAPI.get, {
    params: { id: params.id, token },
    onSuccess: response => {
      initData({
        id: response.id,
        username: response.username,
        email: response.email,
      });
    },
  });
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <form className="flex flex-col relative bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl w-full md:w-1/2">
        {(mutationLoading || queryLoading) && <LoadingOverlay />}
        <div className="p-8 space-y-2">
          <Input
            label="Username"
            value={getData('username')}
            onChange={event => setData('username', event.target.value)}
            error={_.get(error, 'response.data.username')}
          />
          <Input
            label="Email"
            value={getData('email')}
            onChange={event => setData('email', event.target.value)}
            error={_.get(error, 'response.data.email')}
          />
        </div>
        {error && <Alert icon="error" title={error.message} />}
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button
            onClick={() => call({ id: params.id, data: getData(), token })}
            disabled={mutationLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </PageLayout>
  );
};

export default UserUpdatePage;
