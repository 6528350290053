import { useRecoilState } from 'recoil';
import { Icon } from '../../../../../../mds_design_system';
import DataContext from '../../../../contexts/DataContext';
import InteractiveContext from '../../../../contexts/InteractiveContext';

type InputProps = {
  indicator: string;
  index: number;
  type: string;
};

const Input = (props: InputProps) => {
  const { getData, unsetData, setData } = DataContext.useData();
  const [, setSelectedIndex] = useRecoilState(
    InteractiveContext.selectedIndicatorIndexState,
  );
  const value = getData(
    DataContext.path(props.indicator, 'items', props.index, props.type, 'text'),
    '',
  );
  const bbox = getData(
    DataContext.path(props.indicator, 'items', props.index, props.type, 'bbox'),
  );
  return (
    <div
      className="flex bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-nexus-600 sm:max-w-md px-2 items-center"
      onFocus={() => {
        setSelectedIndex(props.index);
      }}
    >
      <input
        type="text"
        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        placeholder="..."
        value={value}
        onChange={event => {
          const value = event.target.value;
          setData(
            value,
            DataContext.path(
              props.indicator,
              'items',
              props.index,
              props.type,
              'text',
            ),
          );
        }}
      />
      {bbox ? (
        <button
          type="button"
          onClick={() =>
            unsetData(
              DataContext.path(
                props.indicator,
                'items',
                props.index,
                props.type,
              ),
            )
          }
        >
          <Icon icon="close" />
        </button>
      ) : null}
    </div>
  );
};

export default Input;
