import { atom } from 'recoil';

const selectedIndicatorState = atom<string | null>({
  key: 'SelectedIndicatorState',
  default: null,
});

const selectedIndicatorIndexState = atom<number>({
  key: 'SelectedIndicatorIndexState',
  default: 0,
});

const selectedSelectionMode = atom<'Text' | 'Area'>({
  key: 'SelectedSelectionMode',
  default: 'Text',
});

const InteractiveContext = {
  selectedIndicatorState,
  selectedIndicatorIndexState,
  selectedSelectionMode,
};

export default InteractiveContext;
