import * as React from 'react';

type TableProps = {
  children?: React.ReactNode;
};

const Table = (props: TableProps): JSX.Element => {
  return (
    <div className="flow-root">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle overflow-hidden">
          <table className="min-w-full divide-y divide-gray-300">
            {props.children}
          </table>
        </div>
      </div>
    </div>
  );
};

export { Table, type TableProps };
