const pageConfig = {
  breadcrumb: [
    {
      label: 'Admin',
      link: '/admin',
    },
    {
      label: 'Users',
      link: '/admin/users',
    },
  ],
  pageTitle: 'MDS Platform Backend - User Management',
  header: 'Users',
};

export default pageConfig;
