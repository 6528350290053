import classNames from 'classnames';
import * as React from 'react';

type BadgeProps = {
  children?: React.ReactNode;
  className?: any;
  type?: 'primary' | 'warning';
};

const Badge = (props: BadgeProps): JSX.Element => {
  return (
    <span
      className={classNames({
        'bg-blue-100 text-blue-800': props.type === 'primary',
        'bg-yellow-100 text-yellow-800': props.type === 'warning',
        'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ':
          true,
      })}
    >
      {props.children}
    </span>
  );
};

export { Badge, type BadgeProps };
