import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilValue } from 'recoil';
import sideMenuConfig from './configs/sidemenu';
import MDSAnnotationToolRoutes from './libraries/mds_annotation_tool/src';
import {
  AuthContext,
  MDSAuthRoutes,
  MDSUserRoutes,
} from './libraries/mds_auth';
import { AppLayout } from './libraries/mds_design_system';
import MDSExtractionDemoRoutes from './libraries/mds_extraction_demo/src/index';

function App() {
  const userToken = useRecoilValue(AuthContext.userTokenState);
  if (userToken) {
    return (
      <div className="App">
        <Routes>
          <Route
            path="/app"
            element={
              <AppLayout sideMenuItems={sideMenuConfig}>
                <Outlet />
              </AppLayout>
            }
          >
            <Route
              path="driving_license_extraction/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="Driving Licence Extraction"
                  basePath="/app/driving_license_extraction"
                  extractionApi="https://driving-license-extractor.app.podder.ai/api/processes/pipeline"
                  fileTypes={['png', 'jpeg', 'jpg']}
                  maxSize={3}
                />
              }
            />
            <Route
              path="passport_extraction/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="Passport Extraction"
                  basePath="/app/passport_extraction"
                  extractionApi="https://passport-extractor.app.podder.ai/api/processes/pipeline"
                  fileTypes={['png', 'jpeg', 'jpg']}
                  maxSize={3}
                />
              }
            />
            <Route
              path="utility_bill_extraction/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="Utility Bill Extraction"
                  basePath="/app/utility_bill_extraction"
                  extractionApi="https://utility-bill-extractor.app.podder.ai/api/processes/pipeline"
                  fileTypes={['png', 'jpeg', 'jpg']}
                  maxSize={3}
                />
              }
            />
            <Route
              path="financial_report_extraction/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="Financial Report Extraction"
                  basePath="/app/financial_report_extraction"
                  extractionApi="https://financial-report-extractor.app.podder.ai/api/processes/pipeline_runner"
                  fileTypes={['pdf']}
                  maxSize={3}
                />
              }
            />
            <Route
              path="bank_statement_extraction/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="Bank Statement Extraction"
                  basePath="/app/bank_statement_extraction"
                  extractionApi="https://bank-statement-extractor.app.podder.ai/api/processes/pipeline"
                  fileTypes={['png', 'jpeg', 'jpg']}
                  maxSize={10}
                />
              }
            />
            <Route
              path="esg_data_scraper/*"
              element={
                <MDSExtractionDemoRoutes
                  modelName="ESG Data Scraper"
                  basePath="/app/esg_data_scraper"
                  extractionApi="https://esg-data-scraper.app.podder.ai/api/processes/pipeline"
                  fileTypes={['pdf']}
                  maxSize={10}
                />
              }
            />
            <Route
              path="annotation_tool/*"
              element={<MDSAnnotationToolRoutes />}
            />
          </Route>
          <Route
            path="/admin"
            element={
              <AppLayout sideMenuItems={sideMenuConfig}>
                <Outlet />
              </AppLayout>
            }
          >
            <Route path="users/*" element={<MDSUserRoutes />} />
          </Route>
          <Route path="*" element={<Navigate to="/app" />} />
        </Routes>
        <ToastContainer />
      </div>
    );
  } else {
    return (
      <div className="App">
        <Routes>
          <Route path="/auth/*" element={<MDSAuthRoutes />} />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
