const pageConfig = {
  breadcrumb: [
    {
      label: 'MDS Annotation Tool',
      link: '/app/annotation_tool/documents',
    },
    {
      label: 'Batch',
      link: '/app/annotation_tool/batches',
    },
  ],
  pageTitle: 'MDS Annotation Tool - New Batch',
  header: 'New Batch',
};

export default pageConfig;
