import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AuthAPI from '../../../apis/auth';
import AuthContext from '../../../contexts/AuthContext';

const LoginPage = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const setUserToken = useSetRecoilState(AuthContext.userTokenState);

  const handleSubmit = () => {
    AuthAPI.login({ email: email, password: password })
      .then(response => {
        setUserToken(response.data.token);
        navigate('/app');
      })
      .catch(error => alert(error.response.data.message));
  };

  return (
    <div className="flex h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-12 w-auto"
              src={require('../../../assets/Logo.png')}
              alt="Your Company"
            />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-600">
              Sign in to your account
            </h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={event => setEmail(event.target.value)}
                      value={email}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={event => setPassword(event.target.value)}
                      value={password}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="flex w-full justify-center rounded-md bg-nexus-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nexus-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nexus-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={require('../../../assets/LoginBackground.png')}
          alt=""
        />
      </div>
    </div>
  );
};

export default LoginPage;
