import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { useRecoilState } from 'recoil';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import HighlightAreas from './highlights';
import HighlightContextMenu from './highlight_context_menu';
import HighlightZone from './highlight_zone';
import CustomRenderPage from './render_page';

type PDFViewerProps = {
  file: string;
};

const PDFViewer = (props: PDFViewerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: HighlightZone.renderToolbar,
  });
  const [selectedSelectionMode] = useRecoilState(
    InteractiveContext.selectedSelectionMode,
  );
  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget: props => <HighlightContextMenu {...props} />,
    renderHighlights: props => <HighlightAreas {...props} />,
    trigger:
      selectedSelectionMode === 'Text' ? Trigger.TextSelection : Trigger.None,
  });
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
      <Viewer
        fileUrl={props.file}
        // @ts-ignore
        plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
        defaultScale={1.5}
        renderPage={props => <CustomRenderPage {...props} />}
      />
    </Worker>
  );
};

export default PDFViewer;
