import classNames from 'classnames';
import * as React from 'react';
import { Breadcrumb } from '../../molecules/Breadcrumb';

type BreadcrumbItemProps = {
  label: string;
  link?: string;
};

type PageLayoutProps = {
  breadcrumbItems?: Array<BreadcrumbItemProps>;
  headerText?: string;
  children: any;
  fullHeight?: boolean;
};

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  return (
    <div
      className={classNames({
        'py-6': true,
        'h-screen max-h-screen': props.fullHeight,
      })}
    >
      <div className="mx-auto px-4 sm:px-6 md:px-8">
        {props.breadcrumbItems ? (
          <Breadcrumb items={props.breadcrumbItems} />
        ) : null}
        {props.headerText ? (
          <h1 className="text-2xl font-semibold text-gray-900">
            {props.headerText}
          </h1>
        ) : null}
      </div>
      <div
        className={classNames({
          'mx-auto px-4 sm:px-6 md:px-8 pb-4': true,
          'h-full': props.fullHeight,
        })}
      >
        <div
          className={classNames({
            'flex justify-center pt-6': true,
            'h-full': props.fullHeight,
          })}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export { PageLayout, type PageLayoutProps };
