// @ts-nocheck
import Select from 'react-select';

type InputSelectProps = {
  label?: string;
  value: any;
  options: Array<OptionProps>;
  onChange: (value: any) => void;
  error?: Array<string>;
  isClearable?: boolean;
  isMulti?: boolean;
};

type OptionProps = {
  label: string;
  value: any;
};

const InputSelect = (props: InputSelectProps) => {
  return (
    <div className="w-full">
      {props.label && (
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}
        </label>
      )}
      <div className="mt-2">
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={props.options}
          value={
            props.isMulti
              ? props.options.filter(option =>
                  props.value.some(_value => _value === option.value),
                )
              : props.options.find(option => option.value === props.value)
          }
          onChange={newValue => {
            if (props.isMulti) {
              if (newValue)
                props.onChange(newValue.map((value: any) => value.value));
              else props.onChange([]);
            } else {
              if (newValue) props.onChange(newValue.value);
              else props.onChange(null);
            }
          }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          isClearable={props.isClearable}
          isMulti={props.isMulti}
        />
      </div>
      {props.error &&
        props.error.map(error => (
          <p className="mt-3 text-sm leading-6 text-red-600">{error}</p>
        ))}
    </div>
  );
};

export { InputSelect, type InputSelectProps };
