import classNames from 'classnames';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useRecoilState } from 'recoil';
import {
  Alert,
  PageTitle,
  useMutation,
  useQuery,
} from '../../../../../mds_design_system';
import { getDocument, updateDocument } from '../../../apis/documents';
import DataContext from '../../../contexts/DataContext';
import InteractiveContext from '../../../contexts/InteractiveContext';
import pageConfig from './config';
import IndicatorList from './IndicatorList';
import InfoBox from './InfoBox';
import InputBox from './InputBox';
import PDFViewer from './PDFViewer';

const DocumentAnnotatePage = () => {
  const params = useParams();
  const [selectedIndicator] = useRecoilState(
    InteractiveContext.selectedIndicatorState,
  );
  const { call } = useMutation(updateDocument, {
    onSuccess: () => alert('Saved'),
  });
  const { setRootData, data } = DataContext.useData();
  const { loading, response, error } = useQuery(getDocument, {
    params: { id: params.id },
    onSuccess: data => setRootData(data.annotate_data || {}),
  });

  if (error) return <Alert icon="error" title={error.message} />;
  if (loading) return <div>Loading</div>;
  if (!response) return <div>No data</div>;

  return (
    <div className="h-full flex flex-row">
      <PageTitle title={pageConfig.pageTitle} />
      <div className="bg-white w-2/12 h-full py-6 px-8 overflow-y-auto overflow-x-hidden">
        <InfoBox
          type={response.type}
          year={response.year}
          name={response.name}
          file={response.file}
          onSave={() => call({ id: params.id, data: { annotate_data: data } })}
        />
        <IndicatorList />
      </div>
      <div className="w-10/12 h-full flex flex-col">
        <div
          className={classNames({
            'h-2/3': selectedIndicator,
            'h-full': !selectedIndicator,
          })}
        >
          <PDFViewer file={response.file} />
        </div>
        <div
          className={classNames({
            'h-1/3': selectedIndicator,
            'h-0': !selectedIndicator,
          })}
        >
          <InputBox language={response.language} />
        </div>
      </div>
      <Tooltip id="metric-tooltip" />
    </div>
  );
};

export default DocumentAnnotatePage;
