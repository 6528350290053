import classNames from 'classnames';
import * as React from 'react';
import { MouseEventHandler } from 'react';

type ButtonProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  onClick?: MouseEventHandler;
};

const Button = (props: ButtonProps): JSX.Element => {
  return (
    <button
      type="button"
      className={classNames({
        'rounded-md bg-nexus-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-nexus-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-nexus-600':
          true,
        'w-full': props.fullWidth,
        disabled: props.disabled,
      })}
      onClick={props.onClick}
    >
      {props.startIcon}
      {props.children}
      {props.endIcon}
    </button>
  );
};

export { Button, type ButtonProps };
