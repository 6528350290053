import * as React from 'react';

type TopLogoProps = {
  minimized?: boolean;
  logo?: string;
};

const TopLogo = (props: TopLogoProps): JSX.Element => {
  return (
    <div className="flex flex-shrink-0 items-center px-4">
      <img
        className="h-8 w-auto"
        src={props.logo || require('../../../../assets/Logo.png')}
        alt="Logo"
      />
    </div>
  );
};

export { TopLogo, type TopLogoProps };
