import classNames from 'classnames';
import * as React from 'react';

type IconProps = {
  icon: string;
  className?: any;
  fill?: boolean;
  size?: number;
};

const Icon = (props: IconProps): JSX.Element => {
  return (
    <span
      className={classNames('material-symbols-outlined', props.className)}
      style={{
        fontVariationSettings: `'FILL' ${props.fill ? 1 : 0}, 'opsz' ${
          props.size || 24
        }`,
      }}
    >
      {props.icon}
    </span>
  );
};

export { Icon, type IconProps };
