const config = {
  local: {
    baseURL:
      process.env.NODE_ENV === 'production'
        ? 'https://mds-api.app.podder.ai/mds_annotation_tool/api'
        : 'http://localhost:5011/mds_annotation_tool/api',
    apis: {
      documents: '/documents',
      batches: '/batches',
    },
  },
};

export default config;
