import axios from 'axios';

export type ExtractionParams = {
  file: File;
};

const extract = async (
  extractionApi: string,
  params: ExtractionParams,
): Promise<any> => {
  return await axios.post(
    extractionApi,
    {
      file: params.file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export default extract;
