import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Badge,
  Box,
  Icon,
  Input,
  LoadingOverlay,
  Pagination,
  Table,
  TD,
  TH,
  useQuery,
} from '../../../../../mds_design_system';
import batchAPI from '../../../apis/batches';
import config from '../../../configs/config';
import { type Batch } from '../../../types';

const BatchTable = () => {
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [searchText, setSearchText] = useState('');
  const { response, error, loading } = useQuery(batchAPI.all, {
    params: { page, searchText },
    reloadOn: [page, searchText],
  });
  return (
    <Box className="w-full space-y-4 relative" padding={false}>
      {loading && <LoadingOverlay />}
      {error && <Alert icon="error" title={error.message} />}
      <div className="my-4 mx-6">
        <div className="w-3/12">
          <Input
            leftIcon="search"
            placeholder="Search by name"
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
          />
        </div>
      </div>
      <Table>
        <thead className="bg-gray-50">
          <Header />
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {response && response.results.map((item: any) => <Row item={item} />)}
        </tbody>
      </Table>
      {response && (
        <Pagination
          page={page}
          size={size}
          total={response.count}
          resultCount={response.results.length}
          onChange={newPage => setPage(newPage)}
        />
      )}
    </Box>
  );
};

const Header = () => (
  <tr>
    <TH>ID</TH>
    <TH>NAME</TH>
    <TH>START</TH>
    <TH>END</TH>
    <TH>STATUS</TH>
    <TH></TH>
  </tr>
);

type RowProps = {
  item: Batch;
};

const Row = (props: RowProps) => {
  const item = props.item;
  const navigate = useNavigate();
  return (
    <tr key={item.id} className="hover:bg-gray-50">
      <TD>{item.id}</TD>
      <TD>{item.name}</TD>
      <TD>{moment(item.start_date).format('ll')}</TD>
      <TD>{moment(item.end_date).format('ll')}</TD>
      <TD>
        <Badge type="primary">{item.status}</Badge>
      </TD>
      <TD>
        <button
          onClick={() => {
            navigate(`/app/annotation_tool/batches/${item.id}/update`);
          }}
        >
          <Icon icon="edit" />
        </button>
        <a href={`${config.local.baseURL}/batches/${item.id}/export`}>
          <Icon icon="download" />
        </a>
        <button>
          <Icon icon="delete" />
        </button>
      </TD>
    </tr>
  );
};

export default BatchTable;
