import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

type MenuItemProps = {
  label: string;
  icon?: any;
  link: string;
  selected?: boolean;
  minimized?: boolean;
};

const MenuItem = (props: MenuItemProps): JSX.Element => {
  return (
    <Link
      to={props.link}
      className={classNames({
        'group flex items-center font-medium rounded-md px-2 py-2': true,
        'bg-gray-100 text-gray-900 text-base': props.selected,
        'text-gray-600 hover:bg-gray-50 hover:text-gray-900 text-sm':
          !props.selected,
      })}
    >
      {props.icon}
      <div className="ml-2">{props.label}</div>
    </Link>
  );
};

export { MenuItem, type MenuItemProps };
