import { useState } from 'react';
import IndicatorTable from './table';

const IndicatorList = () => {
  const [searchText, setSearchText] = useState<string>();
  return (
    <div className="my-2 space-y-4">
      <input
        type="text"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        placeholder="Search in factor"
        onChange={event => setSearchText(event.target.value)}
      />
      <IndicatorTable searchText={searchText} />
    </div>
  );
};

export default IndicatorList;
