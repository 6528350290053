import * as React from 'react';
import { MenuItem } from '../../molecules/MenuItem';

type ItemProps = {
  label: string;
  icon?: any;
  link: string;
};

type SideMenuProps = {
  items: Array<ItemProps>;
  minimized?: boolean;
};

const SideMenu = (props: SideMenuProps): JSX.Element => {
  return (
    <div className="mt-5 flex flex-grow flex-col">
      <nav className="flex-1 space-y-1 px-2 pb-4">
        {props.items &&
          props.items.map(item => (
            <MenuItem
              key={item.label}
              label={item.label}
              link={item.link}
              icon={item.icon}
              minimized={props.minimized}
            />
          ))}
      </nav>
    </div>
  );
};

export { SideMenu, type SideMenuProps };
