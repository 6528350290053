import { AxiosError, AxiosResponse } from 'axios';
import { SetStateAction, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const useQuery = (
  apiRequest: (params?: any) => any,
  options?: {
    params?: any;
    onSuccess?: (response: any) => any;
    reloadOn?: Array<any>;
    cacheLastRequest?: boolean;
  },
) => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const call = () => {
    setLoading(true);
    if (!options?.cacheLastRequest) setResponse(undefined);
    setError(undefined);
    apiRequest(options?.params)
      .then((_response: AxiosResponse) => {
        setResponse(_response.data);
        if (options && options.onSuccess) options.onSuccess(_response.data);
        else toast.success('Success');
      })
      .catch((error: AxiosError) => {
        setError(error);
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => call(), options?.reloadOn || []);

  return { response, error, loading, call };
};

export { useQuery };
