import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '../../atoms/Icon';

type PageSelectProps = {
  page: number;
  size: number;
  total: number;
  onChange: (page: number) => any;
  showSize: 2;
};

const PageSelect = (props: PageSelectProps) => {
  const numPage = Math.ceil(props.total / props.size);
  return (
    <nav
      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
      aria-label="Pagination"
    >
      <div
        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
        onClick={() => props.onChange(Math.max(props.page - 1, 0))}
      >
        <span className="sr-only">Previous</span>
        <Icon icon="keyboard_arrow_left" />
      </div>
      {_.range(
        Math.max(props.page - props.showSize, 0),
        Math.min(props.page + props.showSize, numPage - 1) + 1,
      ).map(page => (
        <PageNumberItem
          key={page}
          page={page}
          selected={page === props.page}
          onChange={() => props.onChange(page)}
        />
      ))}
      <div
        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
        onClick={() => props.onChange(Math.min(props.page + 1, numPage - 1))}
      >
        <span className="sr-only">Next</span>
        <Icon icon="keyboard_arrow_right" />
      </div>
    </nav>
  );
};

type PageNumberItemProps = {
  page: number;
  selected?: boolean;
  onChange: () => any;
};

const PageNumberItem = (props: PageNumberItemProps) => {
  return (
    <div
      aria-current="page"
      className={classNames({
        'relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 cursor-pointer':
          true,
        'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600':
          props.selected,
        'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50':
          !props.selected,
      })}
      onClick={() => props.onChange()}
    >
      {props.page + 1}
    </div>
  );
};

PageSelect.defaultProps = {
  showSize: 2,
};

export default PageSelect;
