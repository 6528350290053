import classNames from 'classnames';
import * as React from 'react';
import { Icon } from '../../../../../../mds_design_system';

type MetricStatusIconProps = {
  type: 'to-do' | 'completed' | 'in-progress' | 'no-disclosure';
};

const MetricStatusIcon = (props: MetricStatusIconProps): JSX.Element => {
  const icons = {
    'to-do': 'check_circle',
    completed: 'check_circle',
    'in-progress': 'warning',
    'no-disclosure': 'do_not_disturb_on',
  };
  return (
    <Icon
      className={classNames({
        'text-gray-300': props.type === 'to-do',
        'text-green-600': props.type === 'completed',
        'text-orange-500': props.type === 'in-progress',
        'text-red-600': props.type === 'no-disclosure',
      })}
      icon={icons[props.type]}
      fill
    />
  );
};

export default MetricStatusIcon;
