import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ConfigContext from './contexts/config_context';
import ResultPage from './pages/result';
import UploadPage from './pages/upload';

type ExtractionDemoRoutesProps = {
  modelName: string;
  basePath: string;
  fileTypes: Array<string>;
  maxSize: number;
  extractionApi: string;
};

const MDSExtractionDemoRoutes = (props: ExtractionDemoRoutesProps) => {
  return (
    <ConfigContext.Provider value={props}>
      <Routes>
        <Route path="upload" element={<UploadPage />} />
        <Route path="result" element={<ResultPage />} />
      </Routes>
    </ConfigContext.Provider>
  );
};

export default MDSExtractionDemoRoutes;
