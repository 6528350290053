import { Icon } from '../Icon';

export type AlertProps = {
  icon: string;
  title: string;
  description?: string;
  listDescription?: Array<string>;
};

export function Alert(props: AlertProps) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0 text-red-800">
          <Icon icon={props.icon} />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{props.title}</h3>
          <div className="mt-2 text-sm text-red-700">
            {props.description && <p>{props.description}</p>}
            {props.listDescription && (
              <ul className="list-disc space-y-1 pl-5">
                {props.listDescription.map(description => (
                  <li key={description}>{description}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
