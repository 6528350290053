import classNames from 'classnames';
import * as React from 'react';

type TDProps = {
  children?: React.ReactNode;
  className?: any;
};

const TD = (props: TDProps): JSX.Element => {
  return (
    <td
      className={classNames(
        'whitespace-nowrap px-2.5 py-3 text-sm text-gray-500',
        props.className,
      )}
    >
      {props.children}
    </td>
  );
};

export { TD, type TDProps };
