import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthContext from './contexts/AuthContext';
import LoginPage from './pages/auth/login';
import UserCreatePage from './pages/users/create';
import UserListPage from './pages/users/list';
import UserUpdatePage from './pages/users/update';

const MDSAuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
    </Routes>
  );
};

const MDSUserRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserListPage />} />
      <Route path="/create" element={<UserCreatePage />} />
      <Route path="/:id/update" element={<UserUpdatePage />} />
    </Routes>
  );
};

export { AuthContext, MDSAuthRoutes, MDSUserRoutes };
