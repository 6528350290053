import _ from 'lodash';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

const dataState = atom<object>({
  key: 'DataState',
  default: {},
});

const useData = (onChange?: (response: any) => any) => {
  const [data, _setData] = useRecoilState(DataContext.dataState);
  useEffect(() => {
    if (onChange) onChange(data);
  }, [data]);

  const setData = (value: any, path: string = '') => {
    const newData = _.cloneDeep(data);
    _.set(newData, path, value);
    _setData(newData);
  };

  const unsetData = (path: string) => {
    const newData = _.cloneDeep(data);
    _.unset(newData, path);
    _setData(newData);
  };

  const pullAt = (path: string, index: number) => {
    const newData = _.cloneDeep(data);
    const array = _.get(data, path);
    const newArray = array.filter(
      (_smt: any, _index: number) => _index !== index,
    );
    _.set(newData, path, newArray);
    _setData(newData);
  };

  const getData = (path: string, defaultValue?: any) =>
    _.get(data, path, defaultValue);

  return { data, setData, unsetData, getData, pullAt, setRootData: _setData };
};

const path = (
  indicator: string,
  value?: 'items' | 'no_disclosure',
  index?: number,
  label?: string,
  attr?: 'text' | 'bbox',
) => {
  if (value === undefined) return indicator;
  if (index === undefined) return `${indicator}.${value}`;
  if (label === undefined) return `${indicator}.${value}[${index}]`;
  if (attr === undefined) return `${indicator}.${value}[${index}].${label}`;
  return `${indicator}.${value}[${index}].${label}.${attr}`;
};

const DataContext = {
  dataState,
  useData,
  path,
};

export default DataContext;
