import { AxiosError, AxiosResponse } from 'axios';
import { SetStateAction, useState } from 'react';

const useMutation = (
  apiRequest: (params: any) => Promise<any>,
  config?: {
    onSuccess?: (response: any) => void;
  },
) => {
  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const call = (params: any) => {
    setLoading(true);
    setResponse(undefined);
    setError(undefined);
    apiRequest(params)
      .then((_response: AxiosResponse) => {
        setResponse(_response.data);
        config && config.onSuccess && config.onSuccess(_response.data);
      })
      .catch((error: SetStateAction<AxiosError<unknown, any> | undefined>) =>
        setError(error),
      )
      .finally(() => setLoading(false));
  };

  return { response, error, loading, call };
};

export { useMutation };
