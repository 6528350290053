import classNames from 'classnames';

type NextBackButtonProps = {
  page: number;
  size: number;
  total: number;
  onChange: (page: number) => any;
};

const NextBackButton = (props: NextBackButtonProps) => {
  const first = props.page === 0;
  const numPage = Math.ceil(props.total / props.size);
  const last = props.page === numPage - 1;
  return (
    <div className="flex flex-1 justify-between sm:hidden">
      <div
        className={classNames({
          'relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ':
            true,
          'opacity-50': first,
          'hover:bg-gray-50 cursor-pointer': !first,
        })}
        onClick={() => !first && props.onChange(props.page - 1)}
      >
        Previous
      </div>
      <div
        className={classNames({
          'relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700':
            true,
          'opacity-50': last,
          'hover:bg-gray-50 cursor-pointer': !last,
        })}
        onClick={() => !last && props.onChange(props.page + 1)}
      >
        Next
      </div>
    </div>
  );
};

export default NextBackButton;
