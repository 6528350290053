import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Input,
  InputFile,
  InputSelect,
  InputSelectAPI,
  LoadingOverlay,
  PageLayout,
  PageTitle,
  useMutation,
} from '../../../../../mds_design_system';
import batchAPI from '../../../apis/batches';
import { createDocument } from '../../../apis/documents';
import document_languages from '../../../data/document_languages.json';
import document_status from '../../../data/document_status.json';
import document_types from '../../../data/document_types.json';
import useForm from '../../../hooks/useForm';
import moduleConfig from '../config';
import pageConfig from './config';

const DocumentCreatePage = () => {
  const { setData, getData } = useForm({ batch: '' });
  const navigate = useNavigate();
  const { call, loading, error } = useMutation(createDocument, {
    onSuccess: () => navigate(moduleConfig.baseURL),
  });
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <form className="flex flex-col relative bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl w-full md:w-1/2">
        {loading && <LoadingOverlay />}
        <div className="p-8 space-y-2">
          <Input
            label="Document name"
            value={getData('name')}
            onChange={event => setData('name', event.target.value)}
            error={_.get(error, 'response.data.name')}
          />
          <Input
            type="number"
            label="Company ID"
            value={getData('company_id')}
            onChange={event => setData('company_id', event.target.value)}
            error={_.get(error, 'response.data.company_id')}
          />
          <InputFile
            label="File"
            value={getData('file')}
            onChange={file => setData('file', file)}
            error={_.get(error, 'response.data.file')}
          />
          <InputSelect
            label="Type"
            options={document_types}
            value={getData('type')}
            onChange={value => setData('type', value)}
            error={_.get(error, 'response.data.type')}
          />
          <Input
            type="number"
            label="Year"
            value={getData('year')}
            onChange={event => setData('year', event.target.value)}
            error={_.get(error, 'response.data.year')}
          />
          <InputSelect
            label="Language"
            options={document_languages}
            value={getData('language')}
            onChange={value => setData('language', value)}
            error={_.get(error, 'response.data.language')}
          />
          <InputSelectAPI
            label="Batch"
            value={getData('batch')}
            onChange={value => setData('batch', value || '')}
            query={batchAPI.all}
            onLoad={response =>
              response.results.map((batch: any) => ({
                label: batch.name,
                value: batch.id,
              }))
            }
            params={{ page: 0, searchText: '' }}
            error={_.get(error, 'response.data.batch')}
            isClearable
          />
          <InputSelect
            label="Status"
            options={document_status}
            value={getData('status')}
            onChange={value => setData('status', value)}
            error={_.get(error, 'response.data.status')}
          />
        </div>
        {error && <Alert icon="error" title={error.message} />}
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button onClick={() => call({ data: getData() })} disabled={loading}>
            Save
          </Button>
        </div>
      </form>
    </PageLayout>
  );
};

export default DocumentCreatePage;
