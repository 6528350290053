import { useNavigate } from 'react-router-dom';
import {
  Button,
  PageLayout,
  PageTitle,
} from '../../../../../mds_design_system';
import moduleConfig from '../config';
import pageConfig from './config';
import BatchTable from './table';

const UserListPage = () => {
  const navigate = useNavigate();
  return (
    <PageLayout
      breadcrumbItems={pageConfig.breadcrumb}
      headerText={pageConfig.header}
    >
      <PageTitle title={pageConfig.pageTitle} />
      <div className="w-full space-y-2">
        <div className="flex justify-between">
          <div />
          <Button onClick={() => navigate(`${moduleConfig.baseURL}/create`)}>
            New User
          </Button>
        </div>
        <BatchTable />
      </div>
    </PageLayout>
  );
};

export default UserListPage;
