import { useContext } from 'react';
import { PageLayout } from '../../../mds_design_system';
import UploadBox from '../components/organisms/UploadBox';
import ConfigContext from '../contexts/config_context';

const UploadPage = (): JSX.Element => {
  const context = useContext(ConfigContext);
  const breadcrumbItems = [
    {
      label: context.modelName,
      link: context.basePath + '/upload',
    },
    {
      label: 'Upload',
    },
  ];

  return (
    <PageLayout
      breadcrumbItems={breadcrumbItems}
      headerText={context.modelName}
    >
      <UploadBox />
    </PageLayout>
  );
};

export default UploadPage;
