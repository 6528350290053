import * as React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Table, TH } from '../../../../../../mds_design_system';
import DataContext from '../../../../contexts/DataContext';
import InteractiveContext from '../../../../contexts/InteractiveContext';
import positions from '../../../../data/annotation_position.json';
import Row from './row';

type TableProps = {
  selectedIndicator: string;
};

const InputTable = (props: TableProps) => {
  const { getData, pullAt, setData } = DataContext.useData();
  const [selectedIndicatorIndex, setSelectedIndicatorIndex] = useRecoilState(
    InteractiveContext.selectedIndicatorIndexState,
  );
  const metricData = getData(
    DataContext.path(props.selectedIndicator, 'items'),
    [],
  );
  const positionPath = DataContext.path(
    props.selectedIndicator,
    'items',
    selectedIndicatorIndex,
    'POSITION',
  );
  const position = getData(positionPath, '');
  const noDisclosure = getData(
    DataContext.path(props.selectedIndicator, 'no_disclosure'),
  );

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: any) => {
      switch (event.key) {
        case 'ArrowDown':
        case 'Tab':
          event.preventDefault();
          if (selectedIndicatorIndex < metricData.length) {
            setSelectedIndicatorIndex(
              Math.min(selectedIndicatorIndex + 1, metricData.length),
            );
          } else {
            setSelectedIndicatorIndex(0);
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          setSelectedIndicatorIndex(Math.max(selectedIndicatorIndex - 1, 0));
          break;
        case 'ArrowLeft':
          event.preventDefault();
          setData(prevPosition(position), positionPath);
          break;
        case 'ArrowRight':
          event.preventDefault();
          setData(nextPosition(position), positionPath);
          break;
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  });

  useEffect(() => {
    const view = document.getElementById(
      `input_table_row_${selectedIndicatorIndex}`,
    );
    view && view.scrollIntoView();
  }, [selectedIndicatorIndex]);

  if (noDisclosure) return null;

  return (
    <Table>
      <thead className="bg-gray-50">
        <tr>
          <TH>LABEL_1</TH>
          <TH>LABEL_2</TH>
          <TH>LABEL_3</TH>
          <TH>DISCLOSURE</TH>
          <TH>UNIT</TH>
          <TH>POSITION</TH>
          <TH></TH>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {metricData.map((item: any, index: number) => (
          <Row
            id={`input_table_row_${index}`}
            key={index}
            indicator={props.selectedIndicator}
            index={index}
            highlight={index === selectedIndicatorIndex}
            onDelete={() => {
              pullAt(DataContext.path(props.selectedIndicator, 'items'), index);
            }}
          />
        ))}
        <Row
          id={`input_table_row_${metricData.length}`}
          indicator={props.selectedIndicator}
          index={metricData.length}
          highlight={metricData.length === selectedIndicatorIndex}
          new
        />
      </tbody>
    </Table>
  );
};

const nextPosition = (position: string) => {
  const index = positions.findIndex(p => p.label === position);
  if (index === positions.length - 1) return positions[0].label;
  return positions[index + 1].label;
};
const prevPosition = (position: string) => {
  const index = positions.findIndex(p => p.label === position);
  if (index <= 0) return positions[positions.length - 1].label;
  return positions[index - 1].label;
};

export default InputTable;
