import * as React from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbItemProps = {
  label: string;
  link?: string;
  first?: boolean;
};

type BreadcrumbProps = {
  items: Array<BreadcrumbItemProps>;
};

const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {props.items.map((item, index) => (
          <BreadcrumbItem
            key={item.label + item.link}
            first={index === 0}
            label={item.label}
            link={item.link}
          />
        ))}
      </ol>
    </nav>
  );
};

const BreadcrumbItem = (props: BreadcrumbItemProps) => (
  <li>
    <div className="flex items-center">
      {!props.first && (
        <svg
          className="h-5 w-5 flex-shrink-0 text-gray-300 mr-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
        </svg>
      )}
      {props.link ? (
        <Link
          to={props.link}
          className="text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          {props.label}
        </Link>
      ) : (
        <div className="text-sm font-medium text-gray-500 hover:text-gray-700">
          {props.label}
        </div>
      )}
    </div>
  </li>
);

export { Breadcrumb, type BreadcrumbProps };
