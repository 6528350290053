import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

type BoxProps = {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  className?: string;
  padding?: boolean;
};

const Box = (props: BoxProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'overflow-hidden bg-white shadow sm:rounded-lg w-full',
        props.className,
      )}
    >
      {props.title && (
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {props.title}
          </h3>
          {props.subTitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {props.subTitle}
            </p>
          )}
        </div>
      )}
      <div
        className={classNames({
          'border-t border-gray-200': true,
          'px-4 py-5': props.padding,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

Box.defaultProps = {
  padding: true,
};

export { Box, type BoxProps };
